import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import router from '@/router';

export default class LetterListViewModel {
  constructor() {
    this.boardData = {
      title:'발송한 레몬레터 목록',
      drawDataList: [
        {
          title:'No.',
          width:'60px',
          value:'id',
          isSorting: true
        },
        // {
        //   title:'발송일',
        //   width:'120px',
        //   value:'date',
        //   filter: { name:'convertDateFormat', value:'YYYY-MM-DD' },
        //   isMobileTitle:true
        // },
        {
          title:'메일 제목',
          width:'',
          value:'title',
          isEllip: true,
          isAlignLeft: true
        },
        {
          title:'등록 일시',
          width:'144px',
          value:'createdAt',
          filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm' },
          isMobileTitle:true
        },
      ],
      option:{
        isTotal: true,
        isSize: true
      }
    };
    this.dataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
  }

  init(){
    this.getLetterList();
  }
  onClickRow(rowId){
    router.push({ name: 'LANDING_LETTER_DETAIL', params: { id : rowId } });
  }
  onSearch(){
    this.getLetterList();
  }
  getLetterList(){
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.LETTER_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.dataList = resultData.data;
      this.paginationData.totalCount = resultData.total;
      this.paginationData.totalPage = resultData.totalPage;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}